import React from "react"

import Layout from "../../../components/layout"
import Band from "../../../components/band"
import UserGuideList from "../../../components/user-guide-list"
import PhoneValidationTopNav from "../../../components/top-nav/phone-validation-top-nav"
import SEO from "../../../components/seo"
import grid from "../../../shared/bootstrap-grid.module.css"

const guides = [
  {
    title: 'Architectural Overview',
    description: 'An architectural overview of the Phone Validation product.',
    time: '15',
    url: '/products/phone-validation/architectural-overview'
  },
  {
    title: 'Application Installation and Initial Setup',
    description: 'Initial setup steps for both Enterprise and Professional Editions.',
    time: '12',
    url: '/products/phone-validation/user-guides/installation-and-setup'
  },
  {
    title: 'Professional -  Configuration and Setup',
    description: 'Step-by-step instructions for creating Display Configurations. A necessary step before displaying and validating records with custom Lightning Web Components.',
    time: '15',
    url: '/products/phone-validation/user-guides/professional-guide'
  },
  {
    title: 'Enterprise - Configuration and Setup',
    description: 'Step-by-step instructions for configuring Enterprise Edition. This edition is for displaying and validating records with standard Salesforce record pages and list views.',
    time: '30',
    url: '/products/phone-validation/user-guides/enterprise-guide'
  }
];

const PhoneValidationUserGuidePage = () => (
  <Layout>
    <SEO title="Phone Validation" keywords={[`tigerface`, `systems`, `salesforce`, `products`, `phone validation`]} />

    <PhoneValidationTopNav />

    <Band>
      <div className={grid.row}>
        <UserGuideList guides={guides} />
      </div>
    </Band>
  </Layout>
);

export default PhoneValidationUserGuidePage;